<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col xs="6">
                    <ValidationProvider name="grade_conversion_id" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('grade_scala')">
                            <b-form-select v-model="formData.grade_conversion_id"
                                           :options="gradeConversionOptions"
                                           :class="errors[0] ? 'is-invalid':''"
                            />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col xs="6">
                    <ValidationProvider name="universities_id" rules="required" v-slot="{valid, errors}">
                        <country-and-university-selectbox-with-filter v-model="formData.university_id"
                                                                      :validateError="errors[0]"
                        />
                    </ValidationProvider>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary"
                          @click="createForm"
                          :disabled="formLoading">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
    // Components
    import CountryAndUniversitySelectboxWithFilter from '@/components/interactive-fields/CountryAndUniversitySelectboxWithFilter'
    import SemestersSelectbox from '@/components/interactive-fields/SemestersSelectbox'

    // Services
    import UniversityGrades from '@/services/UniversityGrades'

    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate'

    export default {
        components: {
            CountryAndUniversitySelectboxWithFilter,
            ValidationProvider,
            ValidationObserver
        },
        props: {
            gradeConversionOptions: {
                type: Array,
                default: []
            }
        },
        data() {
            return {
                formData: {
                    grade_conversion_id: null,
                    from_semester_id: null,
                    to_semester_id: null,
                    university_id: []
                },
                formLoading: false
            }
        },
        methods: {
            async createForm() {
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid) {
                    this.formLoading = true;
                    UniversityGrades.store(this.formData)
                                    .then(response => {
                                        this.$toast.success(this.$t('api.' + response.data.message));
                                        this.$emit('createFormSuccess', true);
                                    })
                                    .catch(e => {
                                        this.showErrors(e, this.$refs.formModalValidate);
                                    })
                                    .finally(() => {
                                        this.formLoading = false
                                    })

                }
            }
        }
    }
</script>

