<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col sm="6" md="4">
                    <ValidationProvider name="grade_conversion_id" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('grade_scala')">
                            <b-form-select v-model="formData.grade_conversion_id"
                                           :options="gradeConversionOptions"
                                           :class="errors[0] ? 'is-invalid':''"
                            />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col sm="6" md="4">
                    <ValidationProvider name="university_id" rules="required" v-slot="{valid, errors}">
                        <country-and-university-selectbox v-model="formData.university_id" :country="formData.country_id"></country-and-university-selectbox>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary"
                          @click="updateForm"
                          :disabled="formLoading">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
    // Components
    import CountryAndUniversitySelectbox from '@/components/interactive-fields/CountryAndUniversitySelectbox.vue'
    import SemestersSelectbox from '@/components/interactive-fields/SemestersSelectbox'

    // Services
    import UniversityGrades from '@/services/UniversityGrades'

    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate'

    export default {
        components: {
            CountryAndUniversitySelectbox,
            SemestersSelectbox,

            ValidationProvider,
            ValidationObserver
        },
        props: {
            formId: {
                type: Number
            },
            gradeConversionOptions: {
                type: Array,
                default: []
            }
        },
        data() {
            return {
                formData: {
                    grade_conversion_id: null,
                    country_id: null,
                    university_id: null,
                },
                formLoading: false
            }
        },
        async created() {
            await this.get(this.formId)
        },
        methods: {
            get(id) {
                UniversityGrades.get(id)
                                .then(response => {
                                    let data = response.data.data;
                                    this.formData = {
                                        grade_conversion_id: data.grade_conversion_id,
                                        country_id: data.country_id,
                                        university_id: data.university_id,
                                    }
                                })
                                .catch(e => {
                                    this.showErrors(e);
                                })
            },
            async updateForm() {
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid && this.formId) {
                    UniversityGrades.update(this.formId, this.formData)
                                    .then(response => {
                                        this.$emit('updateFormSuccess', true);
                                        this.$toast.success(this.$t('api.' + response.data.message));
                                    })
                                    .catch(e => {
                                        this.showErrors(e, this.$refs.formModalValidate);
                                    })
                                    .finally(() => {
                                        this.formLoading = false
                                    })
                }
            }
        }
    }
</script>
